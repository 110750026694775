import React, { useCallback, useEffect } from 'react';
import { Button, Typography, Box, FormControl, Select, MenuItem, Zoom, Skeleton, Modal, Fade, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { Product, Locker } from './interfaces';
import { getCurrentLanguageFromLocalStorage, translate } from '../../utils/languageTranslation.util';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface Props {
    product: Product;
    selectedProduct: Product | undefined;
    selectedProducts?;
    addProduct: (product: Product, quantity: number) => void;
    isAvailableProductsLoading?: boolean;
    currencyISO: string;
    QR?: boolean;
    locker?: Locker | null;
    hasProductsInCart?: boolean;
    setShowProductsInCartWarning?: (value: boolean) => void;
    setProductAvailabilityId?: (id: string) => void;
    isShowBackDropOverlay?: boolean;
}

const ProductCard = ({
    QR,
    product,
    addProduct,
    selectedProduct,
    isAvailableProductsLoading = false,
    currencyISO,
    selectedProducts,
    locker,
    hasProductsInCart,
    setShowProductsInCartWarning,
    setProductAvailabilityId,
    isShowBackDropOverlay
}: Props) => {
    const [quantity, setQuantity] = React.useState(1);
    const [clicked, setClicked] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [image, setImage] = React.useState('');
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:767px)');

    const onBook = useCallback(
        (product: Product, quantity: number) => {
            setClicked(true);
            addProduct(product, quantity);
        },
        [addProduct]
    );

    useEffect(() => {
        if (QR && product.id === locker?.productId && locker.availabilityTimes.length > 0 && product.availability !== 0 && !selectedProduct) {
            addProduct(product, quantity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [QR, locker]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (clicked) {
                setClicked(false);
            }
        }, 2000);

        return () => clearTimeout(timeout);
    }, [clicked]);

    const itemsLeftInStock = selectedProduct ? (product.availability ?? 0) - (selectedProduct.quantity ?? 0) : product.availability ?? 0;

    const handleImage = (value) => {
        setImage(value);
        setOpen(true);
    };

    return (
        <Box sx={{ display: 'flex', mb: 2 }}>
            <Box
                sx={{
                    alignSelf: 'center',
                    width: '120px',
                    marginRight: '16px',
                    textAlign: 'center',
                    cursor: 'pointer'
                }}>
                <img
                    alt="product"
                    src={product.productImageURL}
                    style={{ maxHeight: 120, maxWidth: 120, margin: 'auto' }}
                    onClick={() => handleImage(product.productImageURL)}
                />
            </Box>
            <Modal
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                        backgroundcolor: 'red'
                    }
                }}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition>
                <Fade in={open} timeout={500}>
                    <Box sx={{ padding: 2 }}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 2,
                                width: !isMobile ? 400 : '100%',
                                height: !isMobile ? 'auto' : '100%',
                                borderRadius: 1,
                                position: 'relative'
                            }}>
                            <img src={image} alt="product" style={{ maxWidth: '100%' }} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                sx={{
                                    color: 'black',
                                    backgroundColor: 'white',
                                    marginTop: '10px',
                                    ':hover': { backgroundColor: 'white' }
                                }}
                                onClick={() => setOpen(false)}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            <Box sx={{ flex: '1' }}>
                {/* CRASH ON Immeln for accessories */}
                <Typography variant="h5">{translate(product.name, getCurrentLanguageFromLocalStorage())}</Typography>
                <Typography variant="caption">{translate(product.description, getCurrentLanguageFromLocalStorage())}</Typography>
                {isAvailableProductsLoading ? (
                    <>
                        <Skeleton variant="text" width="63px" height="24px" sx={{ mb: 1, fontSize: '24px', transform: 'none' }} />
                        <Skeleton
                            variant="text"
                            width="63px"
                            height="40px"
                            sx={{
                                mr: '16px',
                                display: 'inline-block',
                                transform: 'none'
                            }}
                        />
                        <Skeleton variant="text" height="40px" width="106px" sx={{ display: 'inline-block', transform: 'none' }} />
                    </>
                ) : (
                    <>
                        <Typography variant="h4" sx={{ mb: 1 }}>
                            {product.cost}
                            {currencyISO}
                        </Typography>
                        <FormControl sx={{ mr: [1, 2], minWidth: 65 }}>
                            <Select
                                value={itemsLeftInStock ? quantity : ''}
                                onChange={(event) => {
                                    const qty = event.target.value as number;
                                    setQuantity(qty);
                                }}
                                sx={{
                                    borderRadius: 20,
                                    maxHeight: 40,
                                    border: itemsLeftInStock ? 'solid 1px #004E31' : 'solid 1px rgba(0, 0, 0, 0.12)'
                                }}
                                MenuProps={{ sx: { zIndex: 2147483647 } }}
                                disabled={itemsLeftInStock <= 0}>
                                {[...Array(itemsLeftInStock).keys()].map((key) => (
                                    <MenuItem key={key + 1} value={key + 1}>
                                        {key + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                borderRadius: 20,
                                height: 40,
                                px: [itemsLeftInStock <= 0 ? 1.5 : 4, 4]
                            }}
                            onClick={() => {
                                onBook(product, quantity);
                                setQuantity(1);
                            }}
                            disabled={itemsLeftInStock <= 0 || QR}>
                            {
                                // eslint-disable-next-line no-nested-ternary
                                clicked ? (
                                    <Zoom in={clicked}>
                                        <CheckIcon />
                                    </Zoom>
                                ) : itemsLeftInStock <= 0 ? (
                                    t('translation.productSelection.fullyBooked')
                                ) : (
                                    t('translation.productSelection.bookbutton')
                                )
                            }
                        </Button>
                        {setProductAvailabilityId && (itemsLeftInStock <= 0 || isShowBackDropOverlay) && (
                            <Button
                                sx={{
                                    zIndex: 1300,
                                    marginTop: '8px',
                                    textTransform: 'none',
                                    height: 40,
                                    borderRadius: 20,
                                    display: 'flex',
                                    minWidth: '185px',
                                    px: [itemsLeftInStock <= 0 ? 1.5 : 4, 4]
                                }}
                                variant="contained"
                                color="primary"
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (hasProductsInCart && setShowProductsInCartWarning) {
                                        setShowProductsInCartWarning(true);
                                    }
                                    setProductAvailabilityId(product.id);
                                }}
                                endIcon={<CalendarMonthIcon fontSize="small" />}>
                                {itemsLeftInStock <= 0
                                    ? t('translation.productSelection.availableDates')
                                    : t('translation.productSelection.selectDate')}
                            </Button>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ProductCard;
