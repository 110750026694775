/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
    Dialog,
    Stepper,
    Step,
    StepLabel,
    Typography,
    AppBar,
    Box,
    IconButton,
    DialogContent,
    DialogActions,
    Skeleton,
    Button,
    CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CloseIcon from '@mui/icons-material/Close';
// import moment from 'moment';
import moment from 'moment-timezone';

import ProductSelection from './ProductSelection';
import EventSelection from './EventSelection';
import AddonsSelection from './AddonsSelection';
import CartStep from './CartStep';
import CurrencyUtil from '../../utils/currency.util';
import OrderConfirm from './OrderConfirm';
import axios from 'axios';
import axiosInstance from './../../utils/axios';
import {
    Product,
    ProductTypes,
    BookingReceipt,
    BookingPaymentProviders,
    Site,
    BookingType,
    EventV1,
    BookableEventV1,
    Locker,
    PromoCodeV2Alpha2,
    PromoCodeCheckoutLightweightV1Alpha1
} from '../product/interfaces';
import LanguageSelector from '../language/LanguageSelector';
import LocalStorageUtil from '../../utils/localStorage.util';
import AppUtil from '../../utils/app.util';
import CompletionBar from './CompletionBar';
import boat from '../../assets/rentalTypes/boat.svg';
import tent from '../../assets/rentalTypes/tent.svg';
import goggles from '../../assets/rentalTypes/goggles.svg';
import helmet from '../../assets/rentalTypes/helmet.svg';
import kayak from '../../assets/rentalTypes/kayak.svg';
import lifejacket from '../../assets/rentalTypes/lifejacket.svg';
import rent from '../../assets/rentalTypes/rent.svg';
import sauna from '../../assets/rentalTypes/sauna.svg';
import trailer from '../../assets/rentalTypes/trailer.svg';
import ski from '../../assets/rentalTypes/ski.svg';
import trunk from '../../assets/rentalTypes/trunk.svg';
import bike2 from '../../assets/rentalTypes/bike2.svg';
import blanket from '../../assets/rentalTypes/blanket.svg';
import plus3 from '../../assets/rentalTypes/plus3.svg';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import EventCartStep from './EventCartStep';
import PromoSelection from './PromoSelection';
import { ForceShowEnum } from '../..';
import PayStep from './PayStep';
import CountdownTimerInMinutes from '../generic/CountdownTimerInMinutes';
import convertToBookingFormat, { formatTimeToDateObject } from '../../utils/dateHelper';
import ReactGA from 'react-ga4';
import { InitialCartData } from './ShowStatusLoadingFrame';
import TermsAndConditionDialog from '../product/TermsAndConditionDialog';
import { BookingFormContext } from './BookingFormContext';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1
    }
}));

interface Props {
    showModal: boolean;
    closeModal: (onConfirmFunction?: () => void) => void;
    siteId: string;
    bookingType: BookingPaymentProviders;
    initialSite: string;
    accountId: string;
    currency: string;
    lockerId: number;
    QR: boolean;
    forceShowType?: ForceShowEnum;
    initialCartData: InitialCartData | null;
    setInitialCartData: (initialCartData: InitialCartData | null) => void;
}

const getInitialBookingEvents = (initialCartData: InitialCartData | null): BookableEventV1[] => {
    const initialBookingEvents: BookableEventV1[] = [];
    if (initialCartData) {
        initialCartData.events.forEach((event) => {
            if (event.bookableEvents) {
                event.bookableEvents.forEach((bookableEvent) => {
                    const newBookableEventOccasion = { ...bookableEvent };

                    newBookableEventOccasion.info = bookableEvent.info ?? undefined;
                    newBookableEventOccasion.quantity = bookableEvent.bookedAttendees;
                    newBookableEventOccasion.bookedAttendees = 0;
                    newBookableEventOccasion.cost = event.cost;
                    newBookableEventOccasion.vat = event.vat;
                    newBookableEventOccasion.priceGroup = undefined;
                    initialBookingEvents.push(newBookableEventOccasion);
                });
            }
        });
    }

    return initialBookingEvents;
};

const getDefaultBookingType = (initialCartData: InitialCartData | null, forceShowType): BookingType | null => {
    let type: BookingType | null = null;
    switch (forceShowType) {
        case ForceShowEnum.Event:
            type = BookingType.EVENTS;
            break;
        case ForceShowEnum.Product:
            type = BookingType.PRODUCTS;
            break;
    }

    if (initialCartData) {
        type = initialCartData.products.length > 0 ? BookingType.PRODUCTS : BookingType.EVENTS;
    }

    return type;
};

const BookingsForm = ({
    showModal,
    closeModal,
    siteId,
    bookingType,
    initialSite,
    lockerId,
    QR,
    forceShowType,
    initialCartData,
    setInitialCartData
}: Props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedProducts, setSelectedProducts] = useState<Product[]>(initialCartData ? initialCartData.products : []);
    const [selectedEventOccations, setSelectedEventOccations] = useState<BookableEventV1[]>(getInitialBookingEvents(initialCartData));
    const [allProducts, setAllProducts] = useState<Product[]>([]);
    const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
    const [totalQuantity, setTotalQuantity] = useState<number>(0);
    const [activeStep, setActiveStep] = useState(initialCartData ? 2 : 0);
    const [dateRange, setDateRange] = useState<[null | Date, null | Date]>(
        initialCartData ? [new Date(initialCartData.startDate), new Date(initialCartData.endDate)] : [null, null]
    );
    const [pickupTime, setPickupTime] = useState(initialCartData ? moment(new Date(initialCartData.startDate)).format('HH:mm') : '');
    const [selectedRentalType, setSelectedRentalType] = useState<any>();
    const [returnTime, setReturnTime] = useState(initialCartData ? moment(new Date(initialCartData.endDate)).format('HH:mm') : '');
    const [receipt, setReceipt] = useState<BookingReceipt | null>(null);
    const [isAvailableProductsLoading, setIsAvailableProductsLoading] = useState<boolean>(false);
    const [siteData, setSiteData] = useState<Site | null>(null);
    const [QRFlow, setQRFlow] = useState<boolean>(QR);
    const [locker, setLocker] = useState<Locker | null>(null);
    const [bookNowFlow, setBookNowFlow] = useState<boolean>(false);
    const [events, setEvents] = useState<EventV1[]>([]);
    const [selectedBookingType, setSelectedBookingType] = useState<BookingType | null>(getDefaultBookingType(initialCartData, forceShowType));
    const [currencyISO, setCurrencyISO] = useState<string>('');
    const selectedLanguageValue = LocalStorageUtil.getItem('user-language-selected') || 'en';
    const [selectedHour, setSelectedHour] = useState(0);
    const [showLockerError, setShowLockerError] = useState(false);
    const [promoCodes, setPromoCodes] = useState<PromoCodeV2Alpha2[]>([]);
    const [selectedPromoCodes, setSelectedPromoCodes] = useState<PromoCodeV2Alpha2[]>([]);
    const [promoCodesCheckout, setPromoCodesCheckout] = useState<PromoCodeCheckoutLightweightV1Alpha1[]>([]);
    const dialogContentRef = useRef(null);
    const [loadingQR, setLoadingQR] = useState(QRFlow && !/[?&]showConfirm=true/.test(window.location.search) ? true : false);
    const { t } = useTranslation();
    const [payOnSite, setPayOnSite] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [publicStripeKey, setPublicStripeKey] = useState('');
    const [sessionExpiryTime, setSessionExpiryTime] = useState('');
    const [orderId, setOrderId] = useState('');
    const [isProductsLoading, setisProductsLoading] = useState(true);
    const [isEventsLoading, setisEventsLoading] = useState(true);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [initialCheckoutErrorMessage, setInitialCheckoutErrorMessage] = useState(
        initialCartData ? t('translation.cartStep.initialCheckoutError') : ''
    );

    useEffect(() => {
        if (selectedLanguageValue !== 'en') {
            import(`moment/locale/${selectedLanguageValue}`)
                .then(() => {
                    moment.locale(selectedLanguageValue, {
                        week: {
                            dow: 1
                        }
                    });
                })
                .catch((error) => console.error(`Could not load moment locale for ${selectedLanguageValue}`, error));
        } else {
            moment.locale('en', {
                week: {
                    dow: 1
                }
            });
        }
    }, [selectedLanguageValue]);

    const closeModalConfirm = () => {
        if (orderId) {
            const expireBooking = function () {
                axiosInstance.patch('/booking/v1/expire/' + orderId);
            };
            closeModal(expireBooking);
        } else {
            closeModal();
        }
    };
    const isInsurance = useMemo(() => {
 
        const productList = availableProducts.length > 0 ? availableProducts : allProducts;
      
        const visibleInsurances = productList.filter((item) => {
            return item.forcingFee === false && item.type === ProductTypes.INSURANCE;
        });

        return visibleInsurances.length > 0;
    }, [allProducts, availableProducts]);
    

    const isAccessory = useMemo(() => {
        const productList = availableProducts.length > 0 ? availableProducts : allProducts;
        return productList.filter((item) => item.type === ProductTypes.ACCESSORY).length > 0;
    }, [allProducts, availableProducts]);
    const steps =
        selectedBookingType === BookingType.EVENTS
            ? [t('translation.steps.events'), t('translation.steps.cart')]
            : isInsurance || isAccessory
            ? [t('translation.steps.products'), t('translation.steps.addons'), t('translation.steps.cart')]
            : [t('translation.steps.products'), t('translation.steps.cart')];
    const promoSteps = ['Giftcards', 'Cart'];
    const rentalTypes = [
        {
            id: 'SUP',
            label: 'SUP, Canoe or Kayak',
            addOn: 'Lifejacket',
            img: kayak,
            img2: lifejacket
        },

        {
            id: 'trailer',
            label: 'Trailer',
            addon: 'other',
            img: trailer,
            img2: plus3
        },

        {
            id: 'bicycle',
            label: 'Bicycle',
            addOn: 'Helmet',
            img: bike2,
            img2: helmet
        },

        {
            id: 'boat',
            addOn: 'lifeVest',
            label: 'Boat',
            img: boat,
            img2: lifejacket
        },

        {
            id: 'sauna',
            label: 'Sauna',
            addOn: 'Trunk',
            img: sauna,
            img2: trunk
        },

        {
            id: 'camping',
            label: 'Camping',
            addOn: 'sheets',
            img: tent,
            img2: blanket
        },
        {
            id: 'ski',
            label: 'Skiing',
            addOn: 'Goggles',
            img: ski,
            img2: goggles
        },
        {
            id: 'other',
            addOn: 'other',
            label: 'Other',
            img: rent,
            img2: plus3
        },
        {
            id: 'hide',
            addOn: 'hide',
            label: 'Hide',
            img: rent,
            img2: plus3
        }
    ];

    const sortProduct = (productA: Product, productB: Product) => {
        if (productA.order < productB.order) return -1;
        if (productA.order > productB.order) return 1;
        return 0;
    };

    const getAllProducts = useCallback(
        async (cancelToken) => {
            try {
                const response1 = await axiosInstance.get(`/product/v1/allProducts/${siteId}`, { cancelToken: cancelToken });
                let result = response1.data as Product[];
                result.map((product) => (product.availability = 1));
                result = result.sort(sortProduct);

                setAllProducts(result);
            } catch (e) {
                console.log(e);
            } finally {
                setisProductsLoading(false);
            }
        },
        [siteId]
    );

    const getPromoCodes = useCallback(
        async (cancelToken) => {
            try {
                const res = await axiosInstance.get(`/promocodes/v1/getPromoCode/${siteId}`, { cancelToken: cancelToken });
                setPromoCodes(res.data);
            } catch (e) {
                console.log(e);
            }
        },
        [siteId]
    );

    const getSiteObject = useCallback(
        async (cancelToken) => {
            try {
                const response1 = await axiosInstance.get(`/sites/v1/getSiteDetails/${siteId}`, { cancelToken: cancelToken });
                setSiteData((response1.data as Site) ?? null);
            } catch (e) {
                console.log(e);
            }

            return [];
        },
        [siteId]
    );

    const getAvailableEvents = useCallback(async () => {
        try {
            const date = moment.tz(siteData?.timezone ?? 'Europe/Stockholm').format('YYYY-MM-DDTHH:mm:ss');
            const response1 = await axiosInstance.get(`/events/v1/availability/${siteId}/${date}`);
            setEvents((response1.data as EventV1[]) ?? null);
        } catch (e) {
            console.log(e);
        } finally {
            setisEventsLoading(false);
        }

        return [];
    }, [siteId, siteData?.timezone]);

    const removeURLParameter = (url: string, parameter: string) => {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {
            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0; ) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    };

    const getLocker = async () => {
        try {
            const res = await axiosInstance.get(`booking/${lockerId}/show`);
            const { bookNowFlow, ...locker } = res.data;
            setBookNowFlow(bookNowFlow);
            setLocker(locker);
        } catch (e) {
            setShowLockerError(true);
        }
    };

    const getQRProduct = () => {
        try {
            // Assuming sitedata.timezone contains the timezone string, e.g., 'America/New_York'
            if (!siteData?.timezone) {
                return console.error('no timezone for site');
            }
            const timezone = siteData.timezone;

            // Use moment-timezone to set the timezone for the starting point of your calculations
            const returnTimeComparison = moment
                .tz(timezone)
                .add(locker ? locker.availabilityTimes[0] + (bookNowFlow ? 0 : 1) : bookNowFlow ? 1 : 2, 'hours');

            const tomorrow = moment.tz(timezone).add(1, 'day').format('YYYY-MM-DD');
            if (returnTimeComparison.isAfter(moment.tz(tomorrow, timezone))) {
                setDateRange([formatTimeToDateObject(moment.tz(timezone)), formatTimeToDateObject(moment.tz(timezone).add(1, 'day'))]);
            } else if (
                moment
                    .tz(timezone)
                    .add(bookNowFlow ? 0 : 1, 'hour')
                    .isBefore(moment.tz(tomorrow, timezone))
            ) {
                setDateRange([formatTimeToDateObject(moment.tz(timezone)), formatTimeToDateObject(moment.tz(timezone))]);
            }

            setPickupTime(
                moment
                    .tz(timezone)
                    .clone()
                    .add(bookNowFlow ? 0 : 1, 'hours')
                    .format(`HH:${bookNowFlow ? 'mm' : '00'}`)
            );

            setReturnTime(returnTimeComparison.format(`HH:${bookNowFlow ? 'mm' : '00'}`));
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const source = axios.CancelToken.source(); // Skapar en CancelToken

        // Anropa dina asynkrona funktioner med den skapade CancelToken
        getAllProducts(source.token);
        getSiteObject(source.token);
        // getAvailableEvents(source.token);
        getPromoCodes(source.token);

        const params: any = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop: any) => searchParams.get(prop)
        });
        const orderId = params.orderId;

        if (orderId) {
            setQRFlow(false);
            const receiptPromise = getOrderReceipt(orderId); // Antar att getOrderReceipt också kan hantera cancelToken
            receiptPromise
                .then((newReceipt: BookingReceipt | null) => {
                    if (newReceipt) {
                        setReceipt(newReceipt);
                        setActiveStep(4);

                        // Remove query-parameters coming from acceptUrl
                        let newUrl = removeURLParameter(window.location.toString(), 'orderId');
                        newUrl = removeURLParameter(newUrl, 'showConfirm');
                        window.history.pushState({}, document.title, newUrl);
                    }
                })
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        console.log(error);
                    }
                });
        }

        return () => {
            source.cancel('Operation canceled by the user.'); // Avbryt alla pågående anrop när komponenten avmonteras
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (siteData) {
            getAvailableEvents();
        }
    }, [getAvailableEvents, siteData]);

    useEffect(() => {
        const quantityArray = selectedProducts.map((selectedProduct) =>
            selectedProduct.type === ProductTypes.INSURANCE ? 0 : selectedProduct.quantity
        );
        const quantity = quantityArray.length > 0 ? quantityArray.reduce((prev, next) => prev + next) : 0;
        setTotalQuantity(quantity);

        // if(QR) {
        //     const quantity = selectedProducts[0]?.availability

        //     setTotalQuantity(quantity);
        // }
    }, [selectedProducts]);

    const eventQuantity = useMemo(() => {
        return selectedEventOccations.reduce(function (a, b) {
            return a + (b.quantity ?? 0);
        }, 0);
    }, [selectedEventOccations]);

    const promoQuantity = useMemo(() => {
        return selectedPromoCodes.length;
    }, [selectedPromoCodes]);

    const getOrderReceipt = useCallback(async (orderId) => {
        try {
            const response1 = await axiosInstance.get(`/payment/v1/bookingReceipt/${orderId}`);
            return response1.data as BookingReceipt;
        } catch (e) {
            console.log(e);
            return null;
        }
    }, []);

    const getAllAvailableProducts = useCallback(async () => {
        try {
            // const startMomentObj = moment(moment.tz(dateRange[0], siteData!.timezone).format('YYYY/MM/DD') + pickupTime, 'YYYY-MM-DDLT');
            // const formattedStartDate = startMomentObj.format('YYYY-MM-DDTHH:mm:s');

            // const endMomentObj = moment(moment.tz(dateRange[1], siteData!.timezone).format('YYYY/MM/DD') + returnTime, 'YYYY-MM-DDLT');
            // const formattedEndDate = endMomentObj.format('YYYY-MM-DDTHH:mm:s');
            const formatString = siteData?.account.timeSettingAmPm ? 'YYYY-MM-DDThh:mm A' : 'YYYY-MM-DDTHH:mm';
            const timeZone = siteData?.timezone ?? 'Europe/Stockholm';

            const dateTimeString = moment.tz(dateRange[0], timeZone).format('YYYY-MM-DD') + 'T' + pickupTime;
            const returnDateTimeString = moment.tz(dateRange[1], timeZone).format('YYYY-MM-DD') + 'T' + returnTime;

            const startDate = moment.tz(dateTimeString, formatString, timeZone);
            const endDate = moment.tz(returnDateTimeString, formatString, timeZone);

            const response1 = await axiosInstance.get(`/product/v1/availableProducts/${siteId}`, {
                params: { startDate: convertToBookingFormat(startDate), endDate: convertToBookingFormat(endDate) }
            });
            return (response1.data as Product[]) ?? [];
        } catch (e) {
            console.log(e);
            return [];
        }
    }, [siteId, dateRange, pickupTime, returnTime]);

    const selectedLanguage = (event) => {
        AppUtil.changeLanguage(event);
    };

    useEffect(() => {
        if (dateRange[0] && dateRange[1] && pickupTime && returnTime && siteData) {
            setIsAvailableProductsLoading(true);
            const prodsPromise = getAllAvailableProducts();
            prodsPromise.then((prods: Product[]) => {
                const forcingFees = prods.filter((product) => {
                    if (product.forcingFee) {
                        product.quantity = 1;
                    }
                    return product.forcingFee;
                });

                prods = prods.sort(sortProduct);
                setAvailableProducts(prods);

                if (QRFlow) {
                    let qr = prods?.filter((p) => p?.id === locker?.productId);
                    qr = qr.map((prod) => {
                        return { ...prod, quantity: 1 };
                    });

                    // qr has to be the first item
                    setSelectedProducts(qr.concat(forcingFees));
                } else if (initialCartData) {
                    setSelectedProducts(initialCartData.products);
                } else {
                    setSelectedProducts(forcingFees);
                }

                setInitialCartData(null);
                setIsAvailableProductsLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, pickupTime, returnTime, locker, siteData]);

    const goToStep = (step: number) => {
        const prods = selectedProducts.filter((selectedProduct) => selectedProduct.type !== ProductTypes.INSURANCE);
        if ((step >= 0 && prods.length > 0) || selectedPromoCodes.length > 0 || selectedEventOccations.length > 0) {
            setActiveStep(step);
        }
    };

    const getPayOnSiteStatus = async () => {
        try {
            const response = await axiosInstance.get(`/sites/v1/getPayOnSite/${siteData!.accountId}`);
            setPayOnSite(response.data.payOnSite);
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    useEffect(() => {
        if (QRFlow && lockerId !== 0) {
            setSelectedBookingType(BookingType.PRODUCTS);
            getLocker();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockerId]);

    useEffect(() => {
        if (QRFlow && locker && locker?.availabilityTimes.length > 0 && siteData) {
            getQRProduct();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locker, siteData]);

    const backToProducts = () => {
        setShowLockerError(false);
        setActiveStep(0);
        setLocker(null);
        setQRFlow(false);
        setSelectedProducts([]);
        setPickupTime('');
        setReturnTime('');
        setDateRange([null, null]);
    };

    useEffect(() => {
        const rt = rentalTypes.filter((type) => type.id === siteData?.rentalType);
        setSelectedRentalType(rt);
        if (siteData) {
            setCurrencyISO(CurrencyUtil.formatCurrencySymbol(siteData?.account.currency || 'SEK'));
            getPayOnSiteStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData]);

    const languageToShowInBookingView = siteData?.languageToShowInBookingView ?? [];

    const [contentRef, setContentRef] = useState<any>(null);

    useEffect(() => {
        setSelectedHour(locker ? locker.availabilityTimes[0] : 0);
    }, [locker]);

    useEffect(() => {
        if (locker) {
            if (locker?.availabilityTimes.length > 0) {
                const filterProds = selectedProducts.filter((product) => product.type !== ProductTypes.INSURANCE);
                if (QRFlow && availableProducts.length > 0 && filterProds.length > 0) {
                    setActiveStep(2);
                }
            } else {
                setLoadingQR(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableProducts, selectedProducts, locker]);

    useEffect(() => {
        if (selectedBookingType === null && !isProductsLoading && !isEventsLoading) {
            const filteredProducts = allProducts.filter((product) => product.type !== ProductTypes.EVENT);
            if (events.length > 0 && filteredProducts.length <= 0) {
                setSelectedBookingType(BookingType.EVENTS);
            } else if (events.length <= 0 && filteredProducts.length > 0) {
                setSelectedBookingType(BookingType.PRODUCTS);
            } else if (events.length > 0 && filteredProducts.length > 0) {
                setSelectedBookingType(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allProducts, events, isProductsLoading, isEventsLoading]);

    // Skip Addon step if there is no accessories and insurance
    const goNextToAddon = () => {
        if (isInsurance || isAccessory) {
            setActiveStep(activeStep + 1);
        } else {
            setActiveStep(activeStep + 2);
        }
    };

    // Skip Addon step if there is no accessories and insurance
    const goBackToAddon = () => {
        if (isInsurance || isAccessory) {
            setActiveStep(activeStep - 1);
        } else {
            setActiveStep(activeStep - 2);
        }
    };

    const setOrderAsExpired = () => {
        if (orderId) {
            axiosInstance.patch('/booking/v1/expire/' + orderId);
            setOrderId('');
        }
    };

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current && siteData && siteData.account.measurementId) {
            ReactGA.initialize(siteData.account.measurementId);
            initialized.current = true; // Set the flag as initialized
        }
    }, [siteData]);

    return (
        <>
            <BookingFormContext.Provider value={{ showTermsAndConditions, setShowTermsAndConditions }}>
                {loadingQR && QRFlow && (
                    <Dialog
                        open={loadingQR}
                        aria-labelledby="form-dialog-title"
                        maxWidth="xs"
                        fullScreen={fullScreen}
                        fullWidth
                        onClose={closeModalConfirm}
                        sx={{ zIndex: 9999, fontSize: '16px' }}>
                        <Box sx={{ mx: 'auto', height: '100vh', py: '60%' }}>
                            <CircularProgress color="primary" size="medium" sx={{ width: '100px' }} />
                        </Box>
                    </Dialog>
                )}

                <Dialog
                    open={showModal}
                    aria-labelledby="form-dialog-title"
                    maxWidth="xs"
                    fullScreen={fullScreen}
                    fullWidth
                    sx={{ zIndex: 999, fontSize: '16px' }}
                    PaperProps={{
                        style: {
                            height: '100%'
                        }
                    }}>
                    <AppBar position="sticky" color="transparent" sx={{ px: 2, pt: '8px', backgroundColor: '#ffffff' }}>
                        <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                            {activeStep === 3 && (
                                <IconButton
                                    aria-label="close"
                                    onClick={() => {
                                        setActiveStep((prev) => prev - 1);
                                        setOrderAsExpired();
                                    }}
                                    sx={{
                                        zIndex: 9999,
                                        marginRight: '8px'
                                    }}>
                                    <ArrowBackIosOutlinedIcon />
                                </IconButton>
                            )}
                            <Box sx={{ flex: 1 }}>
                                <Typography
                                    color="black"
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        lineHeight: '1.5em',
                                        mb: 0
                                    }}>
                                    {!siteData?.name && !receipt?.site?.name && <Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
                                    {activeStep === 4 ? receipt?.site?.name ?? '' : siteData?.name}
                                </Typography>
                            </Box>
                            
                            {activeStep === 3 && sessionExpiryTime && (
                                <div
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        lineHeight: '1.5em',
                                        color: '#000',
                                        margin: '0 8px',
                                        textAlign: 'right'
                                    }}>
                                    <div>{t('translation.paymentStep.finishIn')}</div>
                                    <div>
                                        <CountdownTimerInMinutes
                                            targetDate={sessionExpiryTime}
                                            expireFunction={() => {
                                                setActiveStep((prev) => prev - 1);
                                                setOrderAsExpired();
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {activeStep !== 3 && (
                                <Box>
                                    {languageToShowInBookingView.length > 0 && (
                                        <LanguageSelector
                                            languageValue={selectedLanguageValue}
                                            selectedLanguage={(event) => selectedLanguage(event)}
                                            languageToShowInBookingView={languageToShowInBookingView}></LanguageSelector>
                                    )}
                                </Box>
                            )}
                            <IconButton
                                aria-label="close"
                                onClick={closeModalConfirm}
                                sx={{
                                    zIndex: 9999
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        {siteData?.rentalType !== 'hide' && activeStep !== 3 && (
                            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} sx={{ mb: 1 }}>
                                {steps &&
                                    selectedBookingType !== BookingType.GIFTCARDS &&
                                    steps.map((step) => (
                                        <Step key={step}>
                                            <StepLabel
                                                StepIconComponent={CompletionBar}
                                                StepIconProps={
                                                    {
                                                        totalQuantity:
                                                            selectedBookingType === BookingType.PRODUCTS || selectedBookingType === null
                                                                ? totalQuantity
                                                                : eventQuantity,
                                                        goToStep,
                                                        isAddon: isAccessory || isInsurance,
                                                        selectedRentalType,
                                                        selectedBookingType
                                                    } as any
                                                }>
                                                <Typography sx={{ fontSize: '12px', mt: -1.5 }}>{step}</Typography>
                                            </StepLabel>
                                        </Step>
                                    ))}

                                {steps &&
                                    selectedBookingType === BookingType.GIFTCARDS &&
                                    promoSteps.map((step) => (
                                        <Step key={step}>
                                            <StepLabel
                                                StepIconComponent={CompletionBar}
                                                StepIconProps={
                                                    {
                                                        promoQuantity,
                                                        goToStep,
                                                        isAddon: isAccessory || isInsurance,
                                                        selectedRentalType,
                                                        selectedBookingType
                                                    } as any
                                                }>
                                                <Typography sx={{ fontSize: '12px', mt: -1.5 }}>{step}</Typography>
                                            </StepLabel>
                                        </Step>
                                    ))}
                            </Stepper>
                        )}
                    </AppBar>
                    {activeStep === 0 && (
                        <DialogContent ref={dialogContentRef} style={{ padding: 0, paddingBottom: 0, position: 'relative' }}>
                            <div ref={setContentRef}></div>
                            <Dialog
                                open={events.length > 0 && allProducts.length > 0 && selectedBookingType === null}
                                container={contentRef}
                                maxWidth="xs"
                                onClose={closeModalConfirm}
                                sx={{ zIndex: 2147483647 }}>
                                <DialogContent>
                                    <Typography variant="body1">{t('translation.steps.wantToBook')}</Typography>
                                </DialogContent>
                                <DialogActions sx={{ alignSelf: 'center' }}>
                                    <Button
                                        onClick={() => {
                                            setSelectedBookingType(BookingType.EVENTS);
                                        }}
                                        color="primary"
                                        autoFocus
                                        variant="contained">
                                        {t('translation.steps.events')}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setSelectedBookingType(BookingType.PRODUCTS);
                                        }}
                                        color="primary"
                                        autoFocus
                                        variant="contained">
                                        {t('translation.steps.products')}
                                    </Button>
                                    {promoCodes && promoCodes.filter((promo) => promo.type !== 0).length > 0 && (
                                        <Button
                                            onClick={() => {
                                                setSelectedBookingType(BookingType.GIFTCARDS);
                                            }}
                                            color="primary"
                                            autoFocus
                                            variant="contained">
                                            {t('translation.steps.giftcards')}
                                        </Button>
                                    )}
                                </DialogActions>
                            </Dialog>
                            {siteData &&
                                siteData !== null &&
                                siteData.timePeriods &&
                                (selectedBookingType === null || selectedBookingType === BookingType.PRODUCTS) && (
                                    <ProductSelection
                                        siteId={siteId}
                                        siteData={siteData}
                                        products={availableProducts.length > 0 ? availableProducts : allProducts}
                                        isAvailableProductsLoading={isAvailableProductsLoading}
                                        selectedProducts={selectedProducts}
                                        setSelectedProducts={setSelectedProducts}
                                        goToNextStep={goNextToAddon}
                                        dateRange={dateRange}
                                        pickupTime={pickupTime}
                                        returnTime={returnTime}
                                        setDateRange={setDateRange}
                                        setPickupTime={setPickupTime}
                                        setReturnTime={setReturnTime}
                                        timePeriods={siteData.timePeriods}
                                        siteBookingConfiguration={siteData.siteBookingConfiguration}
                                        currencyISO={currencyISO}
                                        locker={locker}
                                        QR={QRFlow}
                                        setQRFlow={setQRFlow}
                                        dialogContentRef={dialogContentRef}
                                        isAddon={isAccessory || isInsurance}
                                        timeSettingAmPm={siteData.account.timeSettingAmPm}
                                    />
                                )}
                            {siteData && siteData !== null && selectedBookingType === BookingType.EVENTS && (
                                <EventSelection
                                    setSelectedEventOccations={setSelectedEventOccations}
                                    selectedEventOccations={selectedEventOccations}
                                    events={events}
                                    eventQuantity={eventQuantity}
                                    goToCart={() => {
                                        setActiveStep(2);
                                    }}
                                    currencyISO={currencyISO}
                                    timeSettingAmPm={siteData.account.timeSettingAmPm}
                                />
                            )}

                            {siteData && siteData !== null && selectedBookingType === BookingType.GIFTCARDS && (
                                <PromoSelection
                                    setPromoCodesCheckout={setPromoCodesCheckout}
                                    setSelectedPromoCodes={setSelectedPromoCodes}
                                    selectedPromoCodes={selectedPromoCodes}
                                    promoCodes={promoCodes}
                                    promoQuantity={promoQuantity}
                                    goToCart={() => {
                                        setActiveStep(2);
                                    }}
                                    currencyISO={currencyISO}
                                />
                            )}
                        </DialogContent>
                    )}
                    {activeStep === 1 && (
                        <DialogContent style={{ padding: 0, paddingBottom: 0, position: 'relative' }}>
                            <AddonsSelection
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                availableProducts={availableProducts}
                                goToNextStep={() => setActiveStep(activeStep + 1)}
                                goToPrevStep={() => setActiveStep(activeStep - 1)}
                                dateRange={dateRange}
                                pickupTime={pickupTime}
                                returnTime={returnTime}
                                currencyISO={currencyISO}
                                timeSettingAmPm={siteData?.account.timeSettingAmPm ?? false}
                                timeZone={siteData?.timezone ?? 'Europe/Stockholm'}
                            />
                        </DialogContent>
                    )}
                    {activeStep === 2 && (
                        <DialogContent style={{ padding: 0, paddingBottom: 0, position: 'relative' }}>
                            {selectedBookingType === BookingType.EVENTS ? (
                                <EventCartStep
                                    selectedEvents={selectedEventOccations}
                                    setSelectedEvents={setSelectedEventOccations}
                                    availableEvents={events}
                                    goToFirstStep={() => {
                                        setActiveStep(0);
                                    }}
                                    goToPrevStep={() => {
                                        setInitialCheckoutErrorMessage('');
                                        setActiveStep(0);
                                    }}
                                    goToNextStep={() => {
                                        setInitialCheckoutErrorMessage('');
                                        setActiveStep(activeStep + 1);
                                        //setSelectedProducts([]);
                                    }}
                                    goToReceiptStep={() => {
                                        setActiveStep(4);
                                        setSelectedProducts([]);
                                    }}
                                    siteId={siteId}
                                    setReceipt={setReceipt}
                                    bookingType={bookingType}
                                    initialSite={initialSite}
                                    currencyISO={currencyISO}
                                    siteData={siteData}
                                    setClientSecret={setClientSecret}
                                    setPublicStripeKey={setPublicStripeKey}
                                    setSessionExpiryTime={setSessionExpiryTime}
                                    setOrderId={setOrderId}
                                    initialCheckoutErrorMessage={initialCheckoutErrorMessage}
                                    setInitialCheckoutErrorMessage={setInitialCheckoutErrorMessage}
                                />
                            ) : (
                                <CartStep
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                    selectedPromoCodes={selectedPromoCodes}
                                    setSelectedPromoCodes={setSelectedPromoCodes}
                                    promoCodesCheckout={promoCodesCheckout}
                                    setPromoCodesCheckout={setPromoCodesCheckout}
                                    goToFirstStep={() => setActiveStep(0)}
                                    goToPrevStep={() => {
                                        setInitialCheckoutErrorMessage('');
                                        goBackToAddon();
                                    }}
                                    goToNextStep={() => {
                                        setInitialCheckoutErrorMessage('');
                                        setActiveStep(activeStep + 1);
                                        //setSelectedProducts([]);
                                    }}
                                    goToReceiptStep={() => {
                                        setActiveStep(4);
                                        setSelectedProducts([]);
                                    }}
                                    dateRange={dateRange}
                                    pickupTime={pickupTime}
                                    returnTime={returnTime}
                                    siteId={siteId}
                                    payOnSite={payOnSite}
                                    setReceipt={setReceipt}
                                    bookingType={bookingType}
                                    initialSite={initialSite}
                                    currencyISO={currencyISO}
                                    setReturnTime={setReturnTime}
                                    setPickupTime={setPickupTime}
                                    QR={QRFlow}
                                    locker={locker}
                                    bookNowFlow={bookNowFlow}
                                    selectedHour={selectedHour}
                                    setSelectedHour={setSelectedHour}
                                    loadingQR={loadingQR}
                                    setLoadingQR={setLoadingQR}
                                    setQRFlow={setQRFlow}
                                    QRFlow={QRFlow}
                                    setDateRange={setDateRange}
                                    backToProducts={() => backToProducts()}
                                    siteData={siteData}
                                    setClientSecret={setClientSecret}
                                    setPublicStripeKey={setPublicStripeKey}
                                    setSessionExpiryTime={setSessionExpiryTime}
                                    setOrderId={setOrderId}
                                    timeSettingAmPm={siteData?.account.timeSettingAmPm ?? false}
                                    timeZone={siteData?.timezone ?? 'Europe/Stockholm'}
                                    initialCheckoutErrorMessage={initialCheckoutErrorMessage}
                                    setInitialCheckoutErrorMessage={setInitialCheckoutErrorMessage}
                                />
                            )}
                        </DialogContent>
                    )}
                    {activeStep === 3 && (
                        <Box style={{ padding: 0, paddingBottom: 0, position: 'relative', flex: 1 }}>
                            <PayStep clientSecret={clientSecret} publicStripeKey={publicStripeKey} orderId={orderId} />
                        </Box>
                    )}
                    {activeStep === 4 && receipt !== null && (
                        <Box style={{ padding: 0, paddingBottom: 0, position: 'relative' }}>
                            <OrderConfirm receipt={receipt} currencyISO={currencyISO} />
                        </Box>
                    )}
                </Dialog>

                {QRFlow &&
                    locker &&
                    isAvailableProductsLoading === false &&
                    ((selectedProducts.length > 0 && locker?.productId !== selectedProducts[0]?.id) || !selectedProducts[0]?.availability) && (
                        <Dialog open={locker?.productId !== selectedProducts[0]?.id || !selectedProducts[0]?.availability}>
                            <Box sx={{ flex: 1, flexDirection: 'column', textAlign: 'center', padding: 1, margin: 1 }}>
                                <Typography
                                    color="primary"
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        lineHeight: '1.5em',
                                        mb: 0
                                    }}>
                                    {t('translation.errorDialog.title')}
                                </Typography>
                                <Typography>{t('translation.errorDialog.text')}</Typography>
                                <Button onClick={() => backToProducts()} sx={{ border: 1, marginTop: 1 }}>
                                    {t('translation.errorDialog.button')}
                                </Button>
                            </Box>
                        </Dialog>
                    )}

                {QRFlow && showLockerError && (
                    <Dialog open={showLockerError}>
                        <Box sx={{ flex: 1, flexDirection: 'column', textAlign: 'center', padding: 1, margin: 1 }}>
                            <Typography
                                color="primary"
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    lineHeight: '1.5em',
                                    mb: 0
                                }}>
                                {t('translation.errorDialog.title')}
                            </Typography>
                            <Typography>{t('translation.errorDialog.text')}</Typography>
                            <Button onClick={() => backToProducts()} sx={{ border: 1, marginTop: 1 }}>
                                {t('translation.errorDialog.button')}
                            </Button>
                        </Box>
                    </Dialog>
                )}
                <TermsAndConditionDialog showModal={showTermsAndConditions} closeModal={() => setShowTermsAndConditions(false)} siteId={siteId} />
            </BookingFormContext.Provider>
        </>
    );
};

export default BookingsForm;
