import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import './ProductSelection.scss';
import {
    Button,
    Typography,
    Box,
    Stack,
    AppBar,
    Collapse,
    Backdrop,
    Dialog,
    DialogActions,
    DialogContent,
    Badge,
    ButtonGroup,
    Select,
    MenuItem,
    InputAdornment,
    ClickAwayListener
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateRangePickerDay, DateRangePickerDayProps, LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment-timezone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import * as axiosInstance from '../../utils/axios';
import ProductCard from '../product/ProductCard';
import { Product, OpenHour, TimesObject, ProductTypes, TimePeriod, SiteConfigurationV1Alpha1, Locker, Site } from '../product/interfaces';
import Footer from '../footer/Footer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LocalStorageUtil from '../../utils/localStorage.util';

interface Props {
    products: Product[];
    siteId: string;
    siteData: Site;
    selectedProducts: Product[];
    setSelectedProducts: (product: Product[]) => void;
    goToNextStep: () => void;
    dateRange: [null | Date, null | Date];
    pickupTime: string | null;
    returnTime: string | null;
    setDateRange: (dateRange: [null | Date, null | Date]) => void;
    setPickupTime: (pickupTime: string) => void;
    setReturnTime: (returnTime: string) => void;
    isAvailableProductsLoading: boolean;
    timePeriods: TimePeriod[];
    siteBookingConfiguration: SiteConfigurationV1Alpha1;
    currencyISO: string;
    QR: boolean;
    locker: Locker | null;
    setQRFlow: (QRFlow: boolean) => void;
    dialogContentRef: React.RefObject<HTMLDivElement>;
    isAddon: boolean;
    timeSettingAmPm: boolean;
}

interface TotalDayAvailability {
    availability: number;
    date: string;
    dayAvailability: DayAvailability[];
}

interface DayAvailability {
    availability: number;
    date: string;
}

enum DayAvailabilityEnum {
    AVAILABLE,
    PARTIAL,
    FULL,
    NOTSET
}

interface TotalAvailability {
    month: string;
    totalDayAvailability: TotalDayAvailability[];
}

const ProductSelection = ({
    QR,
    products,
    siteId,
    siteData,
    selectedProducts,
    setSelectedProducts,
    goToNextStep,
    dateRange,
    pickupTime,
    returnTime,
    setDateRange,
    setPickupTime,
    setReturnTime,
    isAvailableProductsLoading,
    timePeriods,
    siteBookingConfiguration,
    currencyISO,
    locker,
    setQRFlow,
    isAddon,
    dialogContentRef,
    timeSettingAmPm
}: Props) => {
    const [pickupTimes, setPickupTimes] = useState<TimesObject[]>([]);
    const [returnTimes, setReturnTimes] = useState<TimesObject[]>([]);
    const [showBackDropOverlay, setShowBackDropOverlay] = useState(true);
    const [showDatePickers, setShowDatePickers] = useState(false);
    const [showProductsInCartWarning, setShowProductsInCartWarning] = useState(false);
    const [productAvailabilityId, setProductAvailabilityId] = useState('');
    const [currentMonth, setCurrentMonth] = useState(moment.tz(siteData.timezone));
    const currMonthRef = useRef<Moment | null>(moment());
    const { t } = useTranslation();
    const totalDayAvailability = useRef<TotalDayAvailability[]>([]);
    const monthsAvailability = useRef<TotalAvailability[]>([]);
    const [openPickUpTime, setOpenPickUpTime] = useState(false);
    const [openReturnTime, setOpenReturnTime] = useState(false);
    const [isStartDateOpen, setIsStartDateOpen] = useState(false);
    const [isEndDateOpen, setIsEndDateOpen] = useState(false);
    const [version, setVersion] = useState(0);

    const getProductAvailability = async (month) => {
        const timezone = siteData.timezone;
        const formattedMonth = moment.tz(month, timezone).format('YYYY-MM-DD');

        const monthAvailability = monthsAvailability.current.find((item) => moment.tz(item.month, timezone).format('YYYY-MM-DD') === formattedMonth);

        if (monthAvailability) {
            totalDayAvailability.current = monthAvailability.totalDayAvailability;
        } else {
            const response = await axiosInstance.default.get(
                `/product/v1/getProductAvailabilityForMonth/${siteId}/${productAvailabilityId}/${formattedMonth}`
            );
            const responseData = response.data as TotalDayAvailability[];

            if (currMonthRef.current === month) {
                totalDayAvailability.current = responseData;
            }

            monthsAvailability.current.push({
                month: formattedMonth,
                totalDayAvailability: responseData
            });

            setShowDatePickers(true);
            setVersion((prev) => prev + 1);
        }
    };

    const getNextMonthAvailability = async (month) => {
        const monthAvailability = monthsAvailability.current.find(
            (item) => moment(item.month).format('YYYY-MM-DD') === moment(month).format('YYYY-MM-DD')
        );
        if (!monthAvailability) {
            const response = await axiosInstance.default.get(
                `/product/v1/getProductAvailabilityForMonth/${siteId}/${productAvailabilityId}/${moment(month).format('YYYY-MM-DD')}`
            );
            const responseData = response.data as TotalDayAvailability[];
            monthsAvailability.current.push({
                month: moment(month).format('YYYY-MM-DD'),
                totalDayAvailability: responseData
            });
            setVersion((prev) => prev + 1);
        }
    };

    const getOpenHours = useCallback(
        async (date, isOpenHours = false) => {
            if (!date) {
                return [];
            }
            try {
                const response = await axiosInstance.default.get(
                    `/sites/v1/openHours/${moment.tz(date, siteData.timezone).format('YYYY-MM-DD')}/${siteId}/${isOpenHours}`
                );
                const responseData = response.data as OpenHour[];
                if (responseData && responseData.length > 0) {
                    const returnData = responseData.map((item) => {
                        const timeInSiteTimezone = moment.tz(item.time, 'hh:mm', siteData.timezone);
                        return {
                            hour: timeInSiteTimezone.hour(),
                            text: `${item.time}`,
                            enabled: item.enabled,
                            maxHoursEnable: true
                        };
                    });
                    return returnData;
                }
                return [];
            } catch (e) {
                console.log(e);
                return [];
            }
        },
        [siteId, siteData.timezone]
    );

    const pickupText = useMemo(() => {
        let text = '';
        if (dateRange[0]) {
            const formattedDate = new Intl.DateTimeFormat('default', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                timeZone: siteData.timezone
            }).format(dateRange[0]);
            return formattedDate;
        }
        return text;
    }, [dateRange, pickupTime, timeSettingAmPm]);

    const returnText = useMemo(() => {
        let text = '';
        if (dateRange[1]) {
            const formattedDate = new Intl.DateTimeFormat('default', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                timeZone: siteData.timezone
            }).format(dateRange[1]);

            return formattedDate;
        }
        return text;
    }, [dateRange, returnTime, timeSettingAmPm]);

    const selected = useMemo(
        () => dateRange[0] && dateRange[1] && pickupTime && returnTime && selectedProducts.length > 0,
        [dateRange, pickupTime, returnTime, selectedProducts]
    );

    const onApply = useCallback(() => {
        if (selected) {
            goToNextStep();
        }
    }, [selected, siteId]);

    useEffect(() => {
        (async () => {
            const hours = await getOpenHours(dateRange[0], true);
            setPickupTimes(hours);
        })();
        (async () => {
            const hours = await getOpenHours(dateRange[1], false);
            setReturnTimes(hours);
        })();

        if (!pickupTime || !returnTime) {
            setPickupTime('');
            setReturnTime('');
        }
    }, [dateRange, getOpenHours]);

    useEffect(() => {
        const fetchAndUpdateReturnTimes = async () => {
            const pickupTimeFormat = pickupTime?.includes('AM') || pickupTime?.includes('PM') ? 'hh:mm A' : 'HH:mm';
            const pickupTimeMoment = moment(pickupTime, pickupTimeFormat);

            try {
                const openReturnHours = await getOpenHours(dateRange[1], false);
                const isSameDay = moment(dateRange[0]).isSame(dateRange[1], 'day');
                if (isSameDay) {
                    const validReturnTimes = openReturnHours.filter((time) => {
                        const returnTimeFormat = time.text.includes('AM') || time.text.includes('PM') ? 'hh:mm A' : 'HH:mm';
                        const returnTimeMoment = moment(time.text, returnTimeFormat);
                        return returnTimeMoment.isSameOrAfter(pickupTimeMoment.clone().add(siteBookingConfiguration?.minHours, 'hours'));
                    });
                    setReturnTimes(validReturnTimes);
                } else {
                    setReturnTimes(openReturnHours);
                }
            } catch (error) {
                console.error('Error fetching return times: ', error);
            }
        };

        fetchAndUpdateReturnTimes();
    }, [pickupTime, dateRange, siteBookingConfiguration?.minHours, getOpenHours]);

    useEffect(() => {
        const totalMinHours = (siteBookingConfiguration?.minDays ?? 0) * 24 + (siteBookingConfiguration?.minHours ?? 0);
        const totalMaxHours = (siteBookingConfiguration?.maxDays ?? 0) * 24 + (siteBookingConfiguration?.maxHours ?? 0);

        if (pickupTime) {
            const p = pickupTimes.find((i) => i.text === pickupTime);
            const pickUpMoment = moment(`${moment(dateRange[0]).format('YYYY-MM-DD')}T${pickupTime}`);

            if (!p) return;
            let filteredReturnTimes: any;

            if (moment(dateRange[1]).isAfter(moment(dateRange[0]), 'day')) {
                filteredReturnTimes = returnTimes;
            } else {
                filteredReturnTimes = returnTimes.filter((returnTime) => {
                    const returnMoment = moment(`${moment(dateRange[1]).format('YYYY-MM-DD')}T${returnTime.text}`);
                    const hoursDiff = returnMoment.diff(pickUpMoment, 'hours', true);
                    return hoursDiff >= totalMinHours && hoursDiff <= totalMaxHours;
                });
            }
            setReturnTimes(filteredReturnTimes);
        }

        if (returnTime) {
            const r = returnTimes.find((i) => i.text === returnTime);
            const returnMoment = moment(`${moment(dateRange[1]).format('MM/DD/YYYY')} ${returnTime}`);
            if (!r) return;
            setPickupTimes((pts) =>
                pts.map((r) => {
                    const pickupMomentString = moment(dateRange[0]).format('MM/DD/YYYY');
                    const pickupMoment = moment(`${pickupMomentString} ${r.text}`);
                    var duration = moment.duration(returnMoment.diff(pickupMoment));
                    var hours = duration.asHours();
                    return {
                        ...r,
                        maxHoursEnable: hours >= totalMinHours && (totalMaxHours === 0 ? true : hours <= totalMaxHours)
                    };
                })
            );
        }
    }, [dateRange, pickupTime, returnTime]);

    useEffect(() => {
        if (!dateRange[0]) {
            setIsEndDateOpen(false);
            setOpenPickUpTime(false);
            setIsStartDateOpen(true);
        }

        if (dateRange[0] && !dateRange[1]) {
            setIsStartDateOpen(false);
            setIsEndDateOpen(true);
        }

        if (dateRange[0] && dateRange[1]) {
            setIsStartDateOpen(false);
            setShowDatePickers(false);
            setCurrentMonth(moment.tz(siteData.timezone));
            setIsEndDateOpen(false);

            setTimeout(() => {
                if (!pickupTime) {
                    setOpenPickUpTime(true);
                }
            }, 500);

            if (pickupTime && !returnTime) {
                setOpenPickUpTime(false);
                setOpenReturnTime(true);
            } else if (returnTime) {
                setOpenReturnTime(false);
                setOpenPickUpTime(false);
            }
        }

        if (dateRange[0] && dateRange[1] && pickupTime && returnTime) {
            setShowBackDropOverlay(false);
        } else {
            setShowBackDropOverlay(true);
        }
    }, [dateRange, pickupTime, returnTime]);

    const addProduct = (product: Product, quantity: number) => {
        const selectedProductsReturn = [...selectedProducts];
        const objIndex = selectedProductsReturn.findIndex((selectedProduct) => selectedProduct.id === product.id);
        if (objIndex >= 0) {
            selectedProductsReturn[objIndex].quantity += quantity;
        } else {
            product.quantity = quantity;
            selectedProductsReturn.push(product);
        }

        setSelectedProducts(selectedProductsReturn);

        // Track the add_to_cart event
        if (typeof window.gtag === 'function') {
            const itemsForGTM = [
                {
                    item_id: product.id,
                    item_name: typeof product.name === 'string' ? product.name : product.name.en,
                    price: product.cost,
                    quantity: quantity,
                    item_category: 'Rental Product'
                }
            ];
            // Use gtag to track the add_to_cart event
            window.gtag('event', 'add_to_cart', {
                currency: currencyISO.trim(),
                value: product.cost,
                items: itemsForGTM
            });
        }
    };

    const hasProductsInCart = () => selectedProducts.filter((product) => product.type !== ProductTypes.INSURANCE).length > 0;

    const minDate: Moment = useMemo(() => {
        const today = moment.tz(siteData.timezone);

        if (timePeriods.length === 0) {
            return today;
        }

        let earliestActivePeriod = timePeriods
            .map((timep) => ({
                start: moment.tz(timep.openStartDate, siteData.timezone).startOf('day'),
                end: moment.tz(timep.openEndDate, siteData.timezone).endOf('day')
            }))
            .filter((period) => period.start.isSameOrBefore(today, 'day') && period.end.isSameOrAfter(today, 'day'))
            .sort((a, b) => a.start.valueOf() - b.start.valueOf())[0];

        if (!earliestActivePeriod) {
            earliestActivePeriod = timePeriods
                .map((timep) => ({
                    start: moment.tz(timep.openStartDate, siteData.timezone).startOf('day'),
                    end: moment.tz(timep.openEndDate, siteData.timezone).endOf('day')
                }))
                .filter((period) => period.start.isAfter(today, 'day'))
                .sort((a, b) => a.start.valueOf() - b.start.valueOf())[0];
        }

        let firstSeasonDate: Moment;
        if (earliestActivePeriod) {
            let earliestStartDate = earliestActivePeriod.start;
            firstSeasonDate = earliestStartDate.isAfter(today, 'day') ? earliestStartDate : today;
        } else {
            firstSeasonDate = today;
        }

        return firstSeasonDate;
    }, [timePeriods, siteData.timezone]);

    const minDateDayjs = moment(minDate);

    useEffect(() => {
        if (currentMonth.isBefore(minDateDayjs)) {
            setCurrentMonth(minDateDayjs);
            currMonthRef.current = minDateDayjs;
            // return because the useEffect will be called again with the new currentMonth
            return;
        } else {
            currMonthRef.current = moment(currentMonth).clone();
        }

        if (productAvailabilityId) {
            getProductAvailability(currentMonth).then(() => {
                const nextMonth = moment(currentMonth).add(1, 'month').startOf('month');
                const nextMonthAvailability = monthsAvailability.current.find(
                    (item) => moment(item.month).format('YYYY-MM-DD') === nextMonth.format('YYYY-MM-DD')
                );

                if (!nextMonthAvailability) {
                    getNextMonthAvailability(nextMonth);
                }
            });
        }
    }, [productAvailabilityId, currentMonth, minDate]);

    const maxDate = useMemo(() => {
        let lastSeasonDate = moment();
        timePeriods.forEach((timePeriod) => {
            if (moment(timePeriod.openEndDate).isAfter(lastSeasonDate)) {
                lastSeasonDate = moment(timePeriod.openEndDate);
            }
        });

        return lastSeasonDate;
    }, [timePeriods]);

    // const disableIfAllTimesPassed = (availabilityDay: TotalDayAvailability | undefined): boolean => {
    //     if (!availabilityDay) {
    //         return false;
    //     }

    //     return availabilityDay.dayAvailability.every((timeSlot) => {
    //         const timeSlotMoment = moment.tz(timeSlot.date, siteData.timezone);
    //         return timeSlotMoment.isBefore(moment.tz(siteData.timezone));
    //     });
    // };

    const disableDates = (date: Moment) => {
        if (isEndDateOpen && dateRange[0] && date.isBefore(dateRange[0], 'day')) {
            return true;
        }

        // let latestEndDate = moment(dateRange[0]).add(maxHoursThatYouCanBook, 'hours').add(1, 'day');
        // // if dateRange[0] is todays date then then assign the latestEndDate to the current time + maxHoursThatYouCanBook
        // if (moment(dateRange[0]).isSame(moment.tz(siteData.timezone), 'day')) {
        //     latestEndDate = moment.tz(siteData.timezone).add(maxHoursThatYouCanBook, 'hours');
        // }

        // if (isEndDateOpen && date.isAfter(latestEndDate, 'day')) {
        //     return true;
        // }

        const today = moment.tz(siteData.timezone).startOf('day');
        let momentDate = moment.tz(date.format('YYYY-MM-DD'), siteData.timezone);

        // if (momentDate.isSame(today, 'day')) {
        //     const availabilityDay = getAvailabilityDay(momentDate);
        //     return disableIfAllTimesPassed(availabilityDay);
        // }

        if (momentDate.isBefore(today, 'day')) {
            return true;
        }

        if (dateRange[0] && isEndDateOpen) {
            const pickupDate = moment(dateRange[0]);
            const dayAfterPickup = pickupDate.clone().add(1, 'day');

            // if (date.isSame(pickupDate, 'day') || date.isSame(dayAfterPickup, 'day')) {
            if (date.isSame(pickupDate, 'day')) {
                return false;
            }

            for (let m = dayAfterPickup; m.isBefore(date, 'day'); m.add(1, 'day')) {
                const status = GetDayAvailabilityStatus(m.toDate());
                if (status === DayAvailabilityEnum.PARTIAL || status === DayAvailabilityEnum.FULL) {
                    return true;
                }
            }
        }

        let disableDate = true;
        if (disableDate) {
            timePeriods.forEach((timePeriod) => {
                if (disableDate) {
                    const isBetween = momentDate.isBetween(moment(timePeriod.openStartDate), moment(timePeriod.openEndDate), null, '[]');

                    const weekDay = momentDate.weekday();
                    const openHour = timePeriod.openHours[weekDay];

                    if (
                        isBetween &&
                        openHour &&
                        openHour.open !== '' &&
                        openHour.close !== '' &&
                        openHour.open <= openHour.close &&
                        openHour.open !== openHour.close
                    ) {
                        disableDate = false;

                        // check if the date is today and if the site closed hour has passed
                        if (momentDate.isSame(today, 'day')) {
                            const closingTimeToday = moment.tz(
                                `${momentDate.format('YYYY-MM-DD')}T${openHour.close}`,
                                'YYYY-MM-DDTHH:mm',
                                siteData.timezone
                            );
                            if (momentDate.isSame(today, 'day') && moment.tz(siteData.timezone).isAfter(closingTimeToday)) {
                                disableDate = true;
                            }
                        }

                        if (dateRange[0] && isEndDateOpen) {
                            // last pick up time based on the site closing hour and first return time based on the site opening hour
                            const pickUpDateOpenHour = timePeriod.openHours[moment(dateRange[0]).weekday()];
                            const lastPickUpTime = moment.tz(
                                `${moment(dateRange[0]).format('YYYY-MM-DD')}T${pickUpDateOpenHour.close}`,
                                'YYYY-MM-DDTHH:mm',
                                siteData.timezone
                            );
                            const firstReturnTime = moment.tz(
                                `${moment(momentDate).format('YYYY-MM-DD')}T${openHour.open}`,
                                'YYYY-MM-DDTHH:mm',
                                siteData.timezone
                            );

                            // check if the hour difference between lastPickUpTime and firstReturnTime is less than maxHoursThatYouCanBook
                            const hoursDiff = firstReturnTime.diff(lastPickUpTime, 'hours', true);
                            if (hoursDiff > maxHoursThatYouCanBook) {
                                disableDate = true;
                            }
                        }
                    }
                }
            });
        }

        if (!disableDate) {
            const closedDate = siteBookingConfiguration.closedDates?.find(
                (closedDate) => moment(closedDate.date).toDate().toDateString() === date.toDate().toDateString()
            );
            if (closedDate) {
                if (
                    closedDate.pickupHours.find((pickUpHour) => pickUpHour.enabled) === undefined &&
                    closedDate.returnHours.find((returnHour) => returnHour.enabled) === undefined
                ) {
                    disableDate = true;
                }
            }
        }
        return disableDate;
    };

    const validateReturnTime = (hourAsText: string) => {
        let isValid = true;
        const minHoursRequirement = siteBookingConfiguration?.minHours ?? 0;

        if (dateRange[0] && dateRange[1] && pickupTime) {
            const pickUpMoment = moment(`${moment(dateRange[0]).format('YYYY-MM-DD')}T${pickupTime}`);
            const returnMoment = moment(`${moment(dateRange[1]).format('YYYY-MM-DD')}T${hourAsText}`);

            const isSameDay = moment(dateRange[0]).isSame(dateRange[1], 'day');
            const hoursDiff = returnMoment.diff(pickUpMoment, 'hours', true);

            if (isSameDay && hoursDiff < minHoursRequirement) {
                isValid = false;
            }
        }
        return isValid;
    };

    const [contentRef, setContentRef] = useState<any>(null);

    const maxHoursThatYouCanBook = (siteBookingConfiguration?.maxDays ?? 0) * 24 + (siteBookingConfiguration?.maxHours ?? 0);
    const minHoursThatYouHaveToBook = (siteBookingConfiguration?.minDays ?? 0) * 24 + (siteBookingConfiguration?.minHours ?? 0);

    const getBadge = (dayAvailabilityStatus: DayAvailabilityEnum) => {
        if (dayAvailabilityStatus === DayAvailabilityEnum.NOTSET) {
            return <></>;
        } else if (dayAvailabilityStatus === DayAvailabilityEnum.FULL) {
            return (
                <div
                    className="badge-full"
                    style={{
                        backgroundColor: 'red'
                    }}></div>
            );
        } else if (dayAvailabilityStatus === DayAvailabilityEnum.PARTIAL) {
            return (
                <div
                    className="badge-partial"
                    style={{
                        backgroundColor: 'orange'
                    }}></div>
            );
        } else if (dayAvailabilityStatus === DayAvailabilityEnum.AVAILABLE) {
            return (
                <div
                    className="badge-available"
                    style={{
                        backgroundColor: 'green'
                    }}></div>
            );
        }
    };

    const getAvailabilityDay = (day: Moment) => {
        const dateString = day.format('YYYY-MM-DD');
        let availableDay = totalDayAvailability.current.find(
            (availability) => moment.tz(availability.date, siteData.timezone).format('YYYY-MM-DD') === dateString
        );

        if (!availableDay) {
            monthsAvailability.current.forEach((monthAvailability) => {
                if (moment(monthAvailability.month).format('YYYY-MM') === moment(day).format('YYYY-MM')) {
                    availableDay = monthAvailability.totalDayAvailability.find((availability) => {
                        return moment(availability.date).format('YYYY-MM-DD') === dateString;
                    });
                }
            });
        }

        return availableDay;
    };

    const getDayAvailabilityHours = (day: Date, availabilityDay: TotalDayAvailability | undefined) => {
        if (!availabilityDay) {
            return [];
        }

        let availableHoursToBook = availabilityDay.dayAvailability;
        if (moment(day).isSame(moment(), 'day') && availabilityDay) {
            availableHoursToBook = availabilityDay.dayAvailability.filter((dayAvailable: DayAvailability) => {
                const hourAsDate = moment.tz(dayAvailable.date, siteData.timezone);
                const now = moment.tz(siteData.timezone);

                // Check if hourAsDate is the same hour and minute or later within the same hour
                return hourAsDate.hour() > now.hour() || hourAsDate.hour() === now.hour();
            });
        }

        return availableHoursToBook;
    };

    const GetDayAvailabilityStatus = (day: Date) => {
        let availabilityDay = getAvailabilityDay(moment.tz(day, siteData.timezone));
        if (availabilityDay) {
            const dayAvailabilityHours = getDayAvailabilityHours(day, availabilityDay);
            let availableHoursToBook = dayAvailabilityHours.filter((availability) => availability.availability > 0).length;

            if (availableHoursToBook === 0) {
                return DayAvailabilityEnum.FULL;
            } else if (availableHoursToBook < dayAvailabilityHours.length) {
                return DayAvailabilityEnum.PARTIAL;
            } else {
                return DayAvailabilityEnum.AVAILABLE;
            }
        }

        return DayAvailabilityEnum.NOTSET;
    };

    const hasTimePassed = (timeText, date, timeslot) => {
        const currentTime = moment.tz(siteData.timezone);
        const timeToCompare = moment.tz(`${moment(date).format('YYYY-MM-DD')} ${timeText}`, 'YYYY-MM-DD HH:mm', siteData.timezone);
        switch (timeslot) {
            case 'quarter_hour':
                timeToCompare.add(15, 'minutes');
                break;
            case 'half_hour':
                timeToCompare.add(30, 'minutes');
                break;
            case 'full_hour':
                timeToCompare.add(1, 'hour');
                break;
        }

        return currentTime.isSameOrAfter(timeToCompare);
    };

    const checkIfTimeIsAvailable = (time: TimesObject, date: Date | null, isPickUp: boolean) => {
        if (productAvailabilityId && date) {
            const availabilityDay = getAvailabilityDay(moment.tz(date, siteData.timezone));
            if (availabilityDay) {
                const dayAvailabilityHours = getDayAvailabilityHours(date, availabilityDay);

                if (isPickUp) {
                    const isSameDay = moment.tz(dateRange[0], siteData.timezone).isSame(moment.tz(dateRange[1], siteData.timezone), 'day');

                    if (siteBookingConfiguration.minHours && isSameDay) {
                        const siteClosingTime = dayAvailabilityHours.reduce((latestTime, current) => {
                            const currentEndTime = moment.tz(current.date, siteData.timezone);
                            return current.availability > 0 && currentEndTime.isAfter(latestTime) ? currentEndTime : latestTime;
                        }, moment.tz(date, siteData.timezone).startOf('day'));

                        const latestPossibleStartTime = siteClosingTime.clone().subtract(siteBookingConfiguration.minHours, 'hours');

                        const selectedTime = moment.tz(`${moment(date).format('YYYY-MM-DD')}T${time.text}`, siteData.timezone);

                        if (selectedTime.isAfter(latestPossibleStartTime)) {
                            return false;
                        }

                        for (let i = 0; i < siteBookingConfiguration.minHours * 4; i++) {
                            const timeToCheck = selectedTime.clone().add(15 * i, 'minutes');
                            if (timeToCheck.isAfter(siteClosingTime)) {
                                return false;
                            }
                        }
                    }
                }

                const availability = dayAvailabilityHours.find((availability) => {
                    let tzDate = moment.tz(availability.date, siteData.timezone);
                    if (!isPickUp) {
                        return tzDate.format('HH:mm') === time.text;
                    }
                    return (
                        tzDate.format('HH:mm') === time.text &&
                        availability.availability > 0 &&
                        !hasTimePassed(time.text, date, siteData.timeSlotSetting)
                    );
                });

                // Disables returntTime if date and time are the same as pickupTime
                if (!isPickUp) {
                    const from = moment.tz(dayAvailabilityHours[0].date, siteData.timezone);
                    const minutes = time.text.split(':')[1];
                    const minutesAsInteger = parseInt(minutes);
                    const to = moment.tz(date, siteData.timezone).hours(time.hour).minutes(minutesAsInteger);
                    if (from.format('YYYY-MM-DD HH:mm') === to.format('YYYY-MM-DD HH:mm')) {
                        return true;
                    }
                }
                if (isPickUp === false) {
                    if (dateRange[0] && dateRange[1] && isPickUp === false) {
                        const isSameDay = moment(dateRange[0]).isSame(moment(dateRange[1]), 'day');

                        if (!returnTime && !pickupTime && isSameDay) {
                            return true;
                        }
                        const startHourIndex = dayAvailabilityHours.findIndex((availability) => {
                            let tzDate = moment.tz(availability.date, siteData.timezone);
                            return tzDate.format('HH:mm') === time.text;
                        });

                        const endHourIndex = isSameDay
                            ? dayAvailabilityHours.findIndex((availability) => {
                                  let tzDate = moment.tz(availability.date, siteData.timezone);
                                  return tzDate.format('HH:mm') === pickupTime;
                              })
                            : 0;

                        if (startHourIndex > -1 && endHourIndex > -1) {
                            for (let i = startHourIndex - 1; i >= endHourIndex; i--) {
                                const availability = dayAvailabilityHours[i];
                                if (availability.availability === 0) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                }
                if (availability && availability.availability > 0 && isPickUp === true) {
                    return true;
                }
            }
        } else {
            if (isPickUp && date) {
                const isSameDay = moment.tz(dateRange[0], siteData.timezone).isSame(moment.tz(dateRange[1], siteData.timezone), 'day');

                if (minHoursThatYouHaveToBook > 0 && isSameDay) {
                    const pickupTimeFormat = pickupTime?.includes('AM') || pickupTime?.includes('PM') ? 'hh:mm A' : 'HH:mm';
                    const enabledPickupHours = pickupTimes.filter((time) => time.enabled).sort((a, b) => a.hour - b.hour);
                    if (enabledPickupHours.length === 0) return false;
                    const lastPickupTime = moment
                        .tz(enabledPickupHours[enabledPickupHours.length - 1].text, pickupTimeFormat, siteData.timezone)
                        .subtract(minHoursThatYouHaveToBook, 'hours');
                    const currentTime = moment.tz(time.text, pickupTimeFormat, siteData.timezone);
                    if (currentTime.isAfter(lastPickupTime)) {
                        return false;
                    }
                }
            } else if (isPickUp === false && date && pickupTime) {
                // check if the return time is available both depending on min booking length and max booking length
                const pickupTimeInDate = moment.tz(dateRange[0], siteData.timezone).hours(parseInt(pickupTime.split(':')[0]));
                const returnTimeInDate = moment.tz(date, siteData.timezone).hours(time.hour);
                const hoursDiff = returnTimeInDate.diff(pickupTimeInDate, 'hours');
                if (minHoursThatYouHaveToBook > 0 && hoursDiff < minHoursThatYouHaveToBook) {
                    return false;
                } else if (maxHoursThatYouCanBook > 0 && hoursDiff > maxHoursThatYouCanBook) {
                    return false;
                }
            }
            return true;
        }

        return false;
    };

    const timeFormat = (time: string, timeAmPm: boolean) => {
        if (timeAmPm) {
            return moment(time, 'HH:mm').format('hh:mm A');
        }
        return moment(time, 'HH:mm').format('HH:mm');
    };

    const handleChangePickUpTime = (e) => {
        setReturnTime('');
        setPickupTime(e.target.value);
        setOpenReturnTime(true);
        setOpenPickUpTime(false);
    };

    const handleChangeReturnTime = (e) => {
        setReturnTime(e.target.value);
        setOpenPickUpTime(true);
        setOpenReturnTime(false);
    };

    const availabilityDay = (props: DateRangePickerDayProps<Moment>) => {
        const { day, outsideCurrentMonth, ...otherDayProps } = props;

        if (!day || productAvailabilityId === '') {
            return <DateRangePickerDay outsideCurrentMonth={outsideCurrentMonth} {...otherDayProps} day={day} type="button" />;
        }

        if (day.month() !== currentMonth.month()) {
            return <DateRangePickerDay outsideCurrentMonth={outsideCurrentMonth} {...otherDayProps} day={day} type="button" />;
        }

        const dayAvailabilityStatus = GetDayAvailabilityStatus(day.toDate());

        if (dayAvailabilityStatus === DayAvailabilityEnum.FULL) {
            otherDayProps.disabled = true;
        }

        if (dateRange[0] && !openPickUpTime) {
            const startDay = moment(dateRange[0]);
            const daysBetween = day.diff(startDay, 'day');

            for (let i = 1; i <= daysBetween; i++) {
                const dayToCheck = startDay.add(i, 'day');
                const dayAvailabilityStatus = GetDayAvailabilityStatus(dayToCheck.toDate());

                if (dayAvailabilityStatus === DayAvailabilityEnum.FULL) {
                    otherDayProps.disabled = true;
                }

                let availabilityDay = getAvailabilityDay(moment.tz(dateRange[0], siteData.timezone));
                if (
                    availabilityDay?.dayAvailability &&
                    availabilityDay?.dayAvailability?.length > 0 &&
                    availabilityDay?.dayAvailability[availabilityDay?.dayAvailability.length - 1]?.availability === 0
                ) {
                    otherDayProps.disabled = true;
                }
            }
        }

        if (otherDayProps.disabled) {
            otherDayProps.selected = false;
        }

        const badge = otherDayProps.disabled === false ? getBadge(dayAvailabilityStatus) : null;

        return (
            <React.Fragment>
                <DateRangePickerDay {...otherDayProps} outsideCurrentMonth={outsideCurrentMonth} day={day} />
                {badge && <Badge overlap="circular" badgeContent={badge} />}
            </React.Fragment>
        );
    };

    const selectedLanguageValue = LocalStorageUtil.getItem('user-language-selected') || 'en';

    const buttonClick = (event) => {
        event.stopPropagation();

        const newSelectedProducts = selectedProducts.filter((product) => product.forcingFee);
        setSelectedProducts(newSelectedProducts);
        setQRFlow(false);

        setShowProductsInCartWarning(false);

        setPickupTime('');
        setReturnTime('');
        setDateRange([null, null]);
        setShowDatePickers(true);
        setIsStartDateOpen(true);
    };

    //Google Analytics 4
    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'page_view', {
                page_title: 'Product Page'
            });
        }
    }, []);

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            const itemList = products.filter((item) => item.type === ProductTypes.RENTAL);
            if (itemList.length > 0) {
                const itemsForGTM = itemList.map((item) => ({
                    item_id: item.id,
                    item_name: typeof item.name === 'string' ? item.name : item.name.en,
                    price: item.price,
                    quantity: item.inventory
                }));

                window.gtag('event', 'view_item_list', {
                    item_list_name: 'Products',
                    items: itemsForGTM
                });
            }
        }
    }, [products]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ flexGrow: 1, maxHeight: 'calc(100%-170px)' }}>
                <Stack
                    className="mui-calendar-stack-container"
                    direction="row"
                    sx={{ p: 2, pb: showDatePickers ? 0 : 2, pt: '16px', justifyContent: 'space-between' }}>
                    <Box sx={{ flex: 1, maxWidth: '190px' }} className="pickUp-box">
                        <Typography variant="h5" gutterBottom color="action" sx={{ px: '8px', fontSize: '12px', mb: '8px', textAlign: 'left' }}>
                            {t('translation.productSelection.pickUp')}
                        </Typography>
                        <ButtonGroup orientation="vertical">
                            <Button
                                className="pickup-date"
                                value="check"
                                sx={{
                                    borderWidth: isStartDateOpen ? '2px !important' : '1px !important',
                                    borderBottomWidth: isStartDateOpen ? '1px !important' : '0px !important'
                                }}
                                size="small"
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (hasProductsInCart()) {
                                        setShowProductsInCartWarning(true);
                                    } else {
                                        totalDayAvailability.current = [];
                                        monthsAvailability.current = [];
                                        setProductAvailabilityId('');

                                        setIsStartDateOpen(true);
                                        setShowDatePickers(true);
                                        setIsEndDateOpen(false);
                                    }
                                }}>
                                <CalendarTodayIcon fontSize="small" sx={{ fontSize: '20px', color: '#393939' }} />
                                <Typography variant="button" color="#393939" sx={{ px: 1, textTransform: 'capitalize', fontWeight: '400' }}>
                                    {!pickupText ? t('translation.productSelection.selectPickupDateText') : pickupText}
                                </Typography>
                            </Button>

                            <Select
                                open={openPickUpTime}
                                onOpen={() => {
                                    setOpenPickUpTime(true);
                                    if (!dateRange[1]) {
                                        setDateRange([dateRange[0], dateRange[0]]);
                                    }
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '350px'
                                        }
                                    }
                                }}
                                onClose={() => setOpenPickUpTime(false)}
                                variant="standard"
                                value={pickupTime}
                                disableUnderline
                                onChange={handleChangePickUpTime}
                                sx={{
                                    '& .MuiSelect-icon': { display: 'none' },
                                    '& .MuiSelect-select': { padding: 0, '&:focus': { backgroundColor: 'transparent' } },
                                    maxWidth: 190,
                                    borderRadius: '0px 0px 8px 8px',
                                    border: '1px solid #393939',
                                    height: '40px',
                                    fontSize: '14px',
                                    padding: '5px 7px',
                                    color: '#393939',
                                    borderWidth: openPickUpTime ? '2px' : '1px'
                                }}
                                size="small"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccessTimeIcon sx={{ color: '#393939' }} />
                                    </InputAdornment>
                                }>
                                {pickupTimes.map((time, index) => {
                                    const timeMoment = moment.tz(`${moment(dateRange[0]).format('YYYY-MM-DD')}T${time.text}`, siteData.timezone);
                                    const now = moment.tz(siteData.timezone);
                                    const isToday = dateRange[0] && moment.tz(dateRange[0], siteData.timezone).isSame(now, 'day');
                                    const isPastTime = isToday && timeMoment.isBefore(now);

                                    if (!time.enabled && isToday && isPastTime) {
                                        return null;
                                    }

                                    const displayTime = timeFormat(time.text, timeSettingAmPm);
                                    return (
                                        <MenuItem
                                            value={displayTime}
                                            key={index}
                                            disabled={!checkIfTimeIsAvailable(time, dateRange[0], true) || time.enabled === false}
                                            sx={{ padding: '4px 16px', minHeight: '35px' }}
                                            onClick={() => {
                                                setPickupTime(time.text);
                                            }}>
                                            {displayTime}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </ButtonGroup>
                    </Box>
                    <Box sx={{ flex: 1, maxWidth: '190px' }} className="return-box">
                        <Typography variant="h5" gutterBottom sx={{ px: '8px', fontSize: '12px', mb: '8px', textAlign: 'left' }}>
                            {t('translation.productSelection.return')}
                        </Typography>
                        <ButtonGroup orientation="vertical">
                            <Button
                                className="return-date"
                                sx={{
                                    borderWidth: isEndDateOpen ? '2px !important' : '1px !important',
                                    borderBottomWidth: isEndDateOpen ? '1px !important' : '0px !important'
                                }}
                                size="small"
                                onClick={(event) => {
                                    event.stopPropagation();

                                    if (hasProductsInCart()) {
                                        setShowProductsInCartWarning(true);
                                    } else {
                                        totalDayAvailability.current = [];
                                        monthsAvailability.current = [];
                                        setProductAvailabilityId('');
                                        setShowDatePickers(true);
                                        setIsEndDateOpen(true);
                                        setIsStartDateOpen(false);
                                    }
                                }}>
                                <CalendarTodayIcon fontSize="small" sx={{ fontSize: '20px', color: '#393939' }} />
                                <Typography variant="button" color="#393939" sx={{ px: 1, textTransform: 'capitalize', fontWeight: '400' }}>
                                    {returnText}
                                </Typography>
                            </Button>
                            <Select
                                open={openReturnTime}
                                onOpen={() => setOpenReturnTime(true)}
                                onClose={() => setOpenReturnTime(false)}
                                variant="standard"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '350px'
                                        }
                                    }
                                }}
                                disableUnderline
                                disabled={!pickupTime}
                                value={returnTime}
                                onChange={handleChangeReturnTime}
                                sx={{
                                    '& .MuiSelect-icon': { display: 'none' },
                                    '& .MuiSelect-select': { padding: 0, '&:focus': { backgroundColor: 'transparent' } },
                                    maxWidth: 190,
                                    height: '40px',
                                    borderRadius: '0px 0px 8px 8px',
                                    border: '1px solid #393939',
                                    fontSize: '14px',
                                    padding: '5px 7px',
                                    color: '#393939',
                                    borderWidth: openReturnTime ? '2px' : '1px'
                                }}
                                size="small"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccessTimeIcon sx={{ color: '#393939' }} />
                                    </InputAdornment>
                                }>
                                {returnTimes.length === 0 ? (
                                    <MenuItem sx={{ padding: '4px 16px', minHeight: '35px' }} disabled>
                                        {t('translation.productSelection.noAvailableTimes')}
                                    </MenuItem>
                                ) : (
                                    returnTimes.map((time, index) => {
                                        const timeMoment = moment(`${moment(dateRange[0]).format('YYYY-MM-DD')}T${time.text}`);
                                        const now = moment();
                                        const isToday = dateRange[0] && moment(dateRange[0]).isSame(now, 'day');
                                        const isPastTime = isToday && timeMoment.isBefore(now);

                                        if (!time.enabled && isToday && isPastTime) {
                                            return null;
                                        }

                                        const displayTime = timeFormat(time.text, timeSettingAmPm);

                                        return (
                                            <MenuItem
                                                value={displayTime}
                                                onClick={() => {
                                                    const isValidTime = validateReturnTime(time.text);
                                                    if (isValidTime) {
                                                        setReturnTime(time.text);
                                                    }
                                                }}
                                                sx={{ padding: '4px 16px', minHeight: '35px' }}
                                                disabled={!checkIfTimeIsAvailable(time, dateRange[1], false) || time.enabled === false}
                                                key={index}
                                                color={time.enabled && time.maxHoursEnable ? '#393939' : '#fff'}>
                                                {displayTime}
                                            </MenuItem>
                                        );
                                    })
                                )}
                            </Select>
                        </ButtonGroup>
                    </Box>
                </Stack>
                {showDatePickers && (
                    <Collapse in={showDatePickers} className="collapse-box">
                        <ClickAwayListener
                            onClickAway={() => {
                                setShowDatePickers(false);
                                setCurrentMonth(moment.tz(siteData.timezone));
                            }}>
                            <Box className="box-container">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <StaticDateRangePicker
                                        className="dateRangePicker-box"
                                        value={
                                            dateRange[0] === null && dateRange[1] === null
                                                ? [moment.tz(minDate, siteData.timezone), null]
                                                : [moment.tz(dateRange[0], siteData.timezone), moment.tz(dateRange[1], siteData.timezone)]
                                        }
                                        rangePosition={isEndDateOpen ? 'end' : 'start'}
                                        onMonthChange={(newMonth) => {
                                            const momentNewMonth = moment.tz(newMonth, siteData.timezone);
                                            setCurrentMonth(momentNewMonth);
                                        }}
                                        dayOfWeekFormatter={(day) => `${day}`}
                                        slots={{
                                            toolbar: () => null,
                                            day: (props) => availabilityDay(props)
                                        }}
                                        slotProps={{
                                            actionBar: { actions: [] }
                                        }}
                                        onChange={(newValue) => {
                                            const newStartDate = newValue[0] ? moment.tz(newValue[0], siteData.timezone) : null;
                                            const newEndDate = newValue[1] ? moment.tz(newValue[1], siteData.timezone) : null;

                                            if (isEndDateOpen) {
                                                setDateRange([dateRange[0], newEndDate!.toDate() ?? newStartDate?.toDate()]);
                                                setPickupTime('');
                                                setReturnTime('');
                                            } else {
                                                setDateRange([newStartDate?.toDate() ?? dateRange[0], null]);
                                                setPickupTime('');
                                                setReturnTime('');
                                            }
                                        }}
                                        shouldDisableDate={disableDates}
                                        calendars={1}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </ClickAwayListener>
                    </Collapse>
                )}

                <Box sx={{ p: 2, pt: 0, position: 'relative' }}>
                    <Backdrop
                        sx={{
                            position: 'absolute',
                            color: '#fff',
                            backgroundColor: 'rgb(255 255 255 / 56%)',
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={showBackDropOverlay}
                    />
                    {products.filter((item) => item.type === ProductTypes.RENTAL).length > 0 && (
                        <Typography variant="h4" color="black" gutterBottom sx={{ paddingTop: '10px' }}>
                            {t('translation.productSelection.title')}
                        </Typography>
                    )}
                    {products
                        .filter((item) => item.type === ProductTypes.RENTAL)
                        .map((item, index) => (
                            <ProductCard
                                QR={QR}
                                product={item}
                                key={item.id}
                                addProduct={addProduct}
                                selectedProduct={selectedProducts.find((prod) => prod.id === item.id)}
                                selectedProducts={selectedProducts}
                                isAvailableProductsLoading={isAvailableProductsLoading}
                                currencyISO={currencyISO}
                                locker={locker}
                                isShowBackDropOverlay={showBackDropOverlay}
                                hasProductsInCart={hasProductsInCart()}
                                setShowProductsInCartWarning={setShowProductsInCartWarning}
                                setProductAvailabilityId={(availabiltyId) => {
                                    if (availabiltyId === productAvailabilityId) {
                                        setShowDatePickers(true);
                                    } else {
                                        totalDayAvailability.current = [];
                                        monthsAvailability.current = [];

                                        setPickupTime('');
                                        setReturnTime('');
                                        setDateRange([null, null]);
                                    }

                                    setProductAvailabilityId(availabiltyId);
                                    if (dialogContentRef.current) {
                                        dialogContentRef.current.scrollTop = 0;
                                    }
                                }}
                            />
                        ))}
                </Box>

                <div ref={setContentRef}></div>
                {showProductsInCartWarning && (
                    <Dialog
                        open={showProductsInCartWarning}
                        onClose={() => {
                            setShowProductsInCartWarning(false);
                        }}
                        container={contentRef}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{ zIndex: 2147483647 }}>
                        <DialogContent>
                            <Typography variant="body1">{t('translation.productSelection.alertDialogDescrption')}</Typography>
                        </DialogContent>
                        <DialogActions sx={{ alignSelf: 'center' }}>
                            <Button onClick={(event) => buttonClick(event)} color="primary" autoFocus variant="contained">
                                {t('translation.productSelection.yes')}
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowProductsInCartWarning(false);
                                }}
                                variant="outlined">
                                {t('translation.productSelection.no')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Box>

            <AppBar
                position="sticky"
                color="transparent"
                sx={{
                    top: 'auto',
                    bottom: 0,
                    textAlign: 'center',
                    py: 1,
                    boxShadow: 'none',
                    backgroundColor: '#ffffff',
                    px: 2,
                    zIndex: 9999
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    sx={{ borderRadius: 20, height: 40, px: 4 }}
                    onClick={onApply}
                    disabled={selectedProducts.filter((product) => product.type !== ProductTypes.INSURANCE).length <= 0}>
                    {isAddon ? t('translation.productSelection.goToAddonsButton') : t('translation.addonsSelection.goToCartButton')}
                </Button>
                <Footer />
            </AppBar>
        </Box>
    );
};

export default ProductSelection;
